import React, { useState } from 'react'
import { setConfig } from 'react-hot-loader'

let style = {
  position: 'fixed',
  cursor: 'pointer',
  top: 0,
  right: 0,
  background: 'green',
  color: 'white',
  border: 'none',
  padding: '10px',
  fontFamily: 'sans-serif',
  fontSize: '14px',
}

setConfig({ pureSFC: true })

const renderComponent = process.env.GATSBY_RENDER_REFRESH_COMPONENT

function RefreshContent({ children }) {
  const [refreshed, setRefreshed] = useState(false)

  if (!renderComponent) {
    return false
  }

  function refreshOrReload() {
    if (refreshed) {
      return typeof window !== undefined && window.location.reload()
    }
    var oReq = new XMLHttpRequest()
    oReq.open('POST', '/__refresh')
    oReq.send()
    setRefreshed(true)
  }
  return (
    <button onClick={refreshOrReload} style={style}>
      Refresh{refreshed ? 'ing' : ''} content{refreshed ? ' ... Reload?' : ''}
    </button>
  )
}

export default RefreshContent
