import { currentProjectCssVars } from '../determine-project'
import 'typeface-fira-sans'
import React from 'react'
import Menu from './../components/menu'
import Footer from './../components/footer'
import './../static/iwata-style.css'
import scrollIntoView from 'smooth-scroll-into-view-if-needed'
import { Helmet } from 'react-helmet'
import RefreshContent from '../components/refreshContent'
import KikudooButton from '../components/kikudooButton'
import {
  projectIsTrageberatung,
  projectIsFamillienBegleitung,
  projectIsStoffwindelberatung,
} from '../determine-project'

const getHelmetBodyStyleAttributeValue = () => {
  const style = currentProjectCssVars()
  if (typeof window === 'undefined') {
    return style
  }
  let foo = Object.keys(style)
    .map((key) => `${key}: ${style[key]}`)
    .join(';')
  return foo
}

class DefaultLayout extends React.Component {
  constructor() {
    super()
    this.myRef = React.createRef()
  }

  scroll(ref) {
    ref &&
      scrollIntoView(ref.current, {
        behavior: 'smooth',
        scrollMode: 'if-needed',
      })
  }

  render() {
    return (
      <>
        <Helmet>
          <body style={getHelmetBodyStyleAttributeValue()} />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1"
          />
        </Helmet>
        <section className="text-sm md:text-base bg-base py-2 text-white shadow-md">
          <ul className="hub-menu flex justify-start md:justify-start">
            <li>
              <a
                className={
                  `text-white pr-2 md:pr-4 ` +
                  (projectIsFamillienBegleitung
                    ? 'no-underline hover:underline'
                    : 'hover:no-underline')
                }
                href="https://www.familienbegleitung-bielefeld.de"
              >
                Familienbegleitung
              </a>
            </li>
            <li>
              <a
                className={
                  `text-white pr-2 md:pr-4 ` +
                  (projectIsStoffwindelberatung
                    ? 'no-underline hover:underline'
                    : 'hover:no-underline')
                }
                href="https://www.julia-wickelt.de"
              >
                Stoffwindelberatung
              </a>
            </li>
            <li>
              <a
                className={
                  `text-white pr-2 md:pr-4 ` +
                  (projectIsTrageberatung
                    ? 'no-underline hover:underline'
                    : 'hover:no-underline')
                }
                href="https://www.trageberatung-bielefeld.de"
              >
                Trageberatung
              </a>
            </li>
          </ul>
        </section>
        <a id="top" ref={this.myRef}></a>
        <Menu />
        <main className="section content">
          {/* <RefreshContent /> */}
          <div className="section-inner">{this.props.children}</div>

          <div className="section-inner">
            <div className="post">
              <div className="post-content text-center">
                <hr className="wp-block-separator" />
                <KikudooButton className="mt-5" />
              </div>
            </div>
          </div>
        </main>

        <footer className="section footer bg-accent text-white">
          <div className="section-inner">
            <p className="copyright">
              <Footer />
            </p>
            <p className="contact">
              <span>Kontakt:</span> <a href="tel:01796861549">0179 68 61 549</a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a href="mailto:info@familienbegleitung-bielefeld.de">info@familienbegleitung-bielefeld.de</a>
            </p>

            <a
              href="#top"
              className="arrow active"
              onClick={(event) => {
                event.preventDefault()
                this.scroll(this.myRef)
              }}
            >
              <span></span>
              <span></span>
            </a>
          </div>
        </footer>
      </>
    )
  }
}

export default DefaultLayout
