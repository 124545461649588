import React from 'react'
import Logo from './../images/logo_stoffwindelberatung.svg'
import TrageberatungLogo from './../images/logo_julia-rose_trageberatung.svg'
import FamilienbegleitungLogo from './../images/logo_julia-rose_familienbegleitung.svg'

import {
  projectIsTrageberatung,
  projectIsFamillienBegleitung,
} from '../determine-project'

export default () => {
  if (projectIsTrageberatung) {
    return (
      <TrageberatungLogo
        className="logo md:w-auto"
        width="220px"
        height="90px"
        alt="Julia Rose Trageberatung"
      />
    )
  } else {
    if (projectIsFamillienBegleitung) {
      return (
        <FamilienbegleitungLogo
          className="logo md:w-auto"
          width="220px"
          height="94px"
          alt="Julia Rose Familienbegleitung"
        />
      )
    }
  }
  return (
    <Logo
      className="logo md:w-auto"
      width="270px"
      height="94px"
      alt="Julia Rose Stoffwindelberatung"
    />
  )
}
