import React from 'react'

export default () => {
  return (
    <a
      href="https://kikudoo.com/familienbegleitung-julia-rose/courses"
      rel="noreferrer"
      target="_blank"
      className="mt-5 md:mt-0 text-center md:text-left block md:inline-block self-center hover:bg-base bg-baseDark text-white px-3 py-2 no-underline shadow-lg rounded"
    >
      Beratung oder Workshop buchen
    </a>
  )
}
