const get = require('lodash/get')
require('dotenv').config()

const currentProject = process.env.GATSBY_PROJECT || 'stoffwindelberatung'
exports.currentProject = currentProject

const config = {
  wordpress: {
    baseUrl: 'wp.julia-wickelt.de',
    protocol: 'https',
  },
  stoffwindelberatung: {
    analyticsId: 'UA-133016165-1',
    wordpressUrl: 'https://wp.julia-wickelt.de/',
    wordpress: {
      path: '/',
      hasSubmenus: true,
    },
  },
  trageberatung: {
    analyticsId: 'UA-133016165-2',
    wordpressUrl: 'https://wp.julia-wickelt.de/',
    wordpress: {
      path: '/trageberatung',
      hasSubmenus: false,
    },
  },

  familienbegleitung: {
    // TODO
    analyticsId: 'UA-133016165-3',
    wordpressUrl: 'https://wp.julia-wickelt.de/',
    wordpress: {
      path: '/familienbegleitung',
      hasSubmenus: false,
    },
  },
}

const getProjectConfigValue = (key) => {
  return get(config, `${currentProject}.${key}`)
}
exports.getProjectConfigValue = getProjectConfigValue

const getConfigValue = (key) => {
  return get(config, key)
}
exports.getConfigValue = getConfigValue

exports.projectIsTrageberatung = currentProject === 'trageberatung'
exports.projectIsStoffwindelberatung = currentProject === 'stoffwindelberatung'
exports.projectIsFamillienBegleitung = currentProject === 'familienbegleitung'

exports.currentProjectCssVars = () => {
  const cssVars = {
    default: {
      '--accent-color': '#609fbc',
      '--dark-color': '#3e606f',
    },
    stoffwindelberatung: {
      '--accent-color': '#609fbc',
      '--dark-color': '#3e606f',
    },
    trageberatung: {
      '--accent-color': 'tomato',
      '--dark-color': 'darkred',
    },
  }

  return cssVars.default
}

exports.makeRelativePathFromUrl = (path) => {
  let relativePath = path
    .replace(getProjectConfigValue('wordpressUrl'), '')
    .replace(getProjectConfigValue('wordpress').path.replace('/', ''), '')

  if (relativePath[0] !== '/') {
    relativePath = `/${relativePath}`
  }

  return relativePath
}
